import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Theme from "../../../../vars/ThemeOptions";
import get from "lodash/get";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "../../../../classes/RichText";
import { RichTextReact } from "../../../RichTextReact/RichTextReact";

const Styled = {};

Styled.WrapRow = styled(Row)`
  .slider-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      position: relative;
      z-index: 1;
      background: ${Theme.hex("primary")};
      color: white;
      font-size: 2rem;
      padding: 5px;
      margin: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      position: relative;
      top: -15px;
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
      }
      :first-of-type {
        padding: 5px 7px 5px 5px;
      }
    }
    .container-fluid,
    .container {
      overflow: hidden;
    }
  }
  // @media only screen and (max-width: 991px) {
  //   button {
  //     display: none !important;
  //   }
  // }
`;

Styled.SliderRow = styled(Row)`
  flex-wrap: nowrap;
  // overflow: hidden;
  position: relative;
  z-index: 0;
  left: ${({ curr, data, width }) =>
    curr > 1 && curr < data.length - 1
      ? `-${(curr - 1) * width}px`
      : curr === data.length - 1
      ? `-${(curr - 2) * width}px`
      : 0};
  transition: left 0.3s ease;
  .graphic-piece {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    margin: 2vw;
    text-align: center;
    opacity: 0.35;
    transition: opacity 0.3s ease;
    &.active {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
    h5 {
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      white-space: nowrap;
      margin-top: 1.5rem;
    }
    p {
      font-size: 0.7rem;
    }
    img {
      height: 10vw;
      min-height: 100px;
    }
  }
  @media only screen and (max-width: 991px) {
    left: ${({ curr, width }) => `-${curr * width}px`};
    .graphic-piece {
      opacity: 1 !important;
      img {
        height: 25vw !important;
        min-height: 200px !important;
      }
    }
  }
`;

const EveryWindowSlider = ({ data, currPiece, updateCurrPiece }) => {
  const [slideWidth, setSlideWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const slideRef = useRef();

  useEffect(() => {
    const resize = () => {
      setSlideWidth(slideRef.current.querySelector(".slide").offsetWidth);
      setWindowWidth((prev) => {
        const width =
          prev !== window.outerWidth ? window.outerWidth : windowWidth;
        return width;
      });
    };
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [currPiece, slideWidth, windowWidth]);

  return (
    <Styled.WrapRow>
      <Col className="slider-wrap">
        <button
          onClick={() => updateCurrPiece("prev")}
          aria-label="go to previous piece in slider">
          <FaAngleLeft />
        </button>
        <Container fluid>
          <Styled.SliderRow
            ref={slideRef}
            width={slideWidth}
            curr={currPiece}
            data={data}>
            {data.map((piece, i) => {
              const title = get(piece, `title`);
              const image = get(piece, `image.gatsbyImageData`);
              const alt = get(piece, `image.alt`);
              const copy = new RichText(get(piece, `copy`));
              return (
                <Col xs={12} lg={4} className="slide" key={title + i}>
                  <div
                    className={
                      currPiece === i ? "graphic-piece active" : "graphic-piece"
                    }>
                    {image ? <GatsbyImage image={image} alt={alt} /> : null}
                    <h5>{title}</h5>
                    <RichTextReact content={copy} />
                  </div>
                </Col>
              );
            })}
          </Styled.SliderRow>
        </Container>
        <button
          onClick={() => updateCurrPiece("next")}
          aria-label="go to next piece in slider">
          <FaAngleRight />
        </button>
      </Col>
    </Styled.WrapRow>
  );
};

export default EveryWindowSlider;
