import * as React from "react";
import styled from "styled-components";
import get from "lodash/get";
import SectionTitle from "../../common/Section/SectionTitle";
import Theme from "../../../vars/ThemeOptions";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";

const Wrap = styled.div`
  * {
    transition: all 0.3s ease;
  }
  margin-top: -1rem;
  h5 {
    font-weight: normal;
    font-style: italic;
    font-size: 1rem;
    margin-top: -1rem;
  }
  h6 {
    font-weight: normal;
    font-size: 0.8rem;
    color: ${Theme.hex("primary")};
  }
  h3 {
    font-size: 0.8rem;
    margin-top: 2rem;
  }
`;
const ImgWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  .gatsby-image-wrapper {
    width: 80%;
  }
`;

const DoubleChances = ({ data }) => {
  // console.log('data: ', data);
  const richText1 = new RichText(get(data, "richText1"));
  const titleCopy =
    get(richText1, "json.content[0].content[0].value") +
    get(richText1, "json.content[0].content[1].value");

  const fileRef = get(data, "fileRef");
  const fluid = get(fileRef, "gatsbyImageData");
  const imgTitle = get(fileRef, "title");


  return (
    <Wrap>
      <ImgWrap>
        {fluid ? <GatsbyImage image={fluid} alt={imgTitle} /> : null}
      </ImgWrap>
      <SectionTitle copy={titleCopy} titleColor="black" bold />
      <RichTextReact content={richText1}  />
    </Wrap>
  );
};

export default DoubleChances;
