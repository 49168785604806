import React, { useState, useEffect, useRef } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import styled from "styled-components";
import Theme from "../../../vars/ThemeOptions";

export const PigFormWrap = styled.div`
  width: 30rem;
  &.sticky {
    position: fixed;
    top: 2rem;
    &.bottom {
      position: relative;
      top: 31rem;
    }
    .optional {
      right: 0;
      transition: all 0s ease;
    }
  }
  label {
    display: none;
    &.show {
      display: block;
      margin: 1rem 0 -0.5rem 0.5rem;
      margin-top: 1.5rem;
      margin-bottom: -0.5rem;
    }
  }
  .form-check {
    margin: 0.25rem 0 0.5rem 0.5rem;
    label {
      display: block;
      position: relative;
      top: 0.65rem;
    }
  }
  select {
    padding: 0.375rem 0.5rem;
  }
  margin-top: 2rem;
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    span {
      color: #ff97a8;
    }
  }
  input,
  select,
  .form-control {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${Theme.hex("primary")};
    margin-top: 1rem;
    &:active,
    &:focus {
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: none;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid ${Theme.hex("primary")};
    }
    ::placeholder,
    option {
      color: rgba(0, 0, 0, 0.7) !important;
    }
  }
  label {
    color: rgba(0, 0, 0, 0.7) !important;
  }
  .optional {
    position: absolute;
    z-index: 10;
    right: 5rem;
    text-align: right;
    font-size: 0.8rem;
    transition: all 0s ease;
  }
  .submit-col {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    button {
      border-radius: 0;
      border: 0;
      background: ${Theme.hex("primary")};
      &:hover {
        opacity: 0.8;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    &.sticky,
    &.bottom {
      position: relative;
      top: 0 !important;
      .optional {
        right: 0;
      }
    }
  }
`;

const statesArr = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];
export const howDidHearOptions = [
  "Google Search",
  "Facebook",
  "LinkedIn",
  "Instagram",
  "Neighbor",
  "NextDoor",
  "Special Event",
  "Radio",
  "5280 Magazine",
  "Personal Referral",
  "Networking Group",
  "Yelp",
  "BBB",
  "Other"
];
export const sidingTypeOptions = [
  "James Hardie Siding",
  "Cedar Siding",
  "Composite Wood Siding",
  "Vinyl Siding",
  "Metal Siding",
  "Unsure",
  "Whatever I've got now!",
  "Open to Suggestions"
];

// export const serviceInterests = [
//   "Partial Siding Replacement",
//   "Full Siding Replacement",
//   "Siding Repair",
//   "Window Installation",
//   "Hail Damage Siding",
//   "Vinyl Siding Installation",
//   "Need Help Determining"
// ];

const PinkPigPromoForm = (props) => {
  const [formRect, setFormRect] = useState(); //eslint-disable-line
  const [scrollPos, setScrollPos] = useState(0);
  const formRef = useRef();

  useEffect(() => {
    setFormRect((prev) => {
      const next = !prev ? { y: 0, height: 0, width: 0 } : prev;
      return next;
    });

    const handleResize = () => {
      setFormRect(formRef.current.getBoundingClientRect());
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setFormRect]);

  useScrollPosition(({ currPos }) => {
    setScrollPos((prev) => {
      const cur = currPos.y * -1;
      const pos = prev !== cur ? cur : scrollPos;
      return pos;
    });
  });

  const formSubmit = (e) => {
    let myForm = document.getElementById("pig-form");
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString()
    })
      .then(() => console.log("Form successfully submitted"))
      .then(() => (window.location.href = "/thank-you"))
      .catch((error) => alert(error));
    e.preventDefault();
  };
  return (
    <PigFormWrap
      ref={formRef}
      // className={scrollPos > 275 && scrollPos < 725 ? "sticky" : scrollPos > 725 ? "sticky bottom" : null}
    >
      <h2>
        ENTER TO WIN A <span>PINK PIG</span> TRAEGER SMOKER AND GRILL
      </h2>
      <Form
        name="pink_pig_promo"
        id="pig-form"
        method="post"
        data-netlify="true"
        onSubmit={formSubmit}>
        <input type="hidden" name="form-name" value="pink_pig_promo" />
        <Container fluid="true">
          <Row>
            <Col lg={6}>
              {/* <label htmlFor="firstName">First Name</label> */}
              <Form.Control
                type="text"
                placeholder="First Name"
                id="firstName"
                name="firstName"
                required={true}
              />
            </Col>
            <Col lg={6}>
              {/* <label htmlFor="lastName">Last Name</label> */}
              <Form.Control
                type="text"
                placeholder="Last Name"
                id="lastName"
                name="lastName"
                required={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <label htmlFor="email">Email Address</label> */}
              <Form.Control
                type="email"
                placeholder="Email Address"
                id="email"
                name="email"
                required={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <label htmlFor="phone">Phone Number</label> */}
              <Form.Control
                type="tel"
                placeholder="Phone Number"
                id="phone"
                name="phone"
                required={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Form.Control
                type="text"
                placeholder="Street Address"
                id="addressStreet"
                name="addressStreet"
                required={true}
              />
            </Col>
            <Col lg={6}>
              <Form.Control
                type="text"
                placeholder="City"
                id="addressCity"
                name="addressCity"
                required={true}
              />
            </Col>
            <Col lg={6}>
              <Form.Control
                as="select"
                id="addressState"
                name="addressState"
                required={true}
                // defaultValue="Colorado"
              >
                <option disabled={true} selected={true} value="">
                  Choose a State
                </option>
                {statesArr.map((state, i) => (
                  <option key={`${state}-${i}`}>{state}</option>
                ))}
              </Form.Control>
            </Col>
            <Col lg={6}>
              <Form.Control
                type="text"
                placeholder="Postal Code"
                id="addressPostalCode"
                name="addressPostalCode"
                required={true}
              />
            </Col>
          </Row>
          <label htmlFor="fullOrPartial">
            Project Type <span>*</span>
          </label>
          <Row>
            <Col>
              <Form.Control
                as="select"
                id="fullOrPartial"
                name="fullOrPartial"
                required={true}
                // defaultValue="Which service are you interested in?"
              >
                <option disabled={true} selected={true} value="">
                  Which service are you interested in?
                </option>
                <option>Partial Siding Replacement</option>
                <option>Full Siding Replacement</option>
                <option>Siding Repair</option>
                <option>Window Installation</option>
                <option>Hail Damage Siding</option>
                <option>Vinyl Siding Installation</option>
                <option>Need Help Determining</option>
              </Form.Control>
            </Col>
          </Row>
          <label htmlFor="howDidYouHearAboutUs">
            How did you hear about us? <span>*</span>
          </label>
          <Row>
            <Col>
              <Form.Control
                as="select"
                id="howDidYouHearAboutUs"
                name="howDidYouHearAboutUs"
                required={true}>
                <option disabled={true} selected={true} value="">
                  How did you hear about us?
                </option>
                {howDidHearOptions.map((option, i) => (
                  <option key={`${option}-${i}`}>{option}</option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <label htmlFor="timeline">
            Timeline <span>*</span>
          </label>
          <Row>
            <Col>
              <Form.Control
                as="select"
                id="timeline"
                name="timeline"
                required={true}
                // defaultValue="How soon do you want to start?"
              >
                <option disabled={true} selected={true} value="">
                  How soon do you want to start?
                </option>
                <option>1-2 Weeks</option>
                <option>More than 2 Weeks</option>
                <option>Timing is Flexible</option>
              </Form.Control>
            </Col>
          </Row>
          <label htmlFor="desiredSidingType">
            Desired Siding <span>*</span>
          </label>
          <Row>
            <Col>
              <Form.Control
                as="select"
                id="desiredSidingType"
                name="desiredSidingType"
                required={true}
                // defaultValue="Select a Siding Type"
              >
                <option disabled={true} selected={true} value="">
                  Select a Siding Type
                </option>
                {sidingTypeOptions.map((option, i) => (
                  <option key={`${option}-${i}`}>{option}</option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <label htmlFor="financing" className="show">
            Interested in Financing?
          </label>
          <Row>
            <Col>
              <Form.Check
                type="checkbox"
                id="financing"
                name="financing"
                label="Yes, send me information about applying for financing."
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label htmlFor="message">Message</label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Message*"
                id="message"
                name="message"
              />
            </Col>
          </Row>
          <div className="optional">
            <span>*</span>optional
          </div>
          <Row>
            <Col className="submit-col">
              <Button type="submit">Get A Quote</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </PigFormWrap>
  );
};

export default PinkPigPromoForm;
