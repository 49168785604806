import * as React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Hero from "./Hero/Hero";
import SelectingMaterial from "./SelectingMaterial/SelectingMaterial";
import EducationalApproach from "./EducationalApproach";
import OurProcess from "./OurProcess";
import Testimonials from "./Testimonials";
import Download from "./Download";
import Theme from "../../../vars/ThemeOptions";
import get from "lodash/get";

const Wrap = styled.div`
  overflow: hidden;
  p {
    color: ${Theme.hex("grayText")};
  }
`;

const IdentifyingSidingNeedsPage = (props) => {
  const data = useStaticQuery(query);
  const introTitle = get(data, `introIdentifying.title`);
  const introText = get(data, `introIdentifying.richText1.json`);
  const conditions = get(data, `sidingConditions.edges`).map((p) => p.node);
  const materials = get(data, `materials.edges`).map((p) => p.node);
  const educationalApproach = get(data, `educationalApproach`);
  const workerDivider = get(data, `workerDivider`);
  const ourProcess = get(data, `ourProcess.edges`).map((p) => p.node);
  const testimonials = get(data, `testimonials.edges`).map((p) => p.node);
  const houseDivider = get(data, `houseDivider`);
  const download = get(data, `download`);
  const downloadPreview = get(data, `downloadPreview`);
  const downloadBG = get(data, `downloadBG`);
  return (
    <Wrap>
      <Hero title={introTitle} introText={introText} conditions={conditions} />
      <SelectingMaterial materials={materials} />
      <EducationalApproach data={educationalApproach} divider={workerDivider} />
      <OurProcess data={ourProcess} />
      <Testimonials data={testimonials} divider={houseDivider} />
      <Download
        gtm={{
          event: "brochureDownload",
          campaign: "sidingNeeds",
        }}
        data={download}
        preview={downloadPreview}
        bg={downloadBG}
      />
    </Wrap>
  );
};

export default IdentifyingSidingNeedsPage;

const query = graphql`
  query {
    materials: allContentfulMaterials(sort: { fields: order, order: ASC }) {
      edges {
        node {
          ...NodeMaterialsFields
        }
      }
    }
    introIdentifying: contentfulGlobal(
      title: { eq: "Identifying Your Siding Needs" }
    ) {
      ...NodeGlobalFields
    }
    sidingConditions: allContentfulTitleCopyImage(
      filter: { section: { eq: "intro" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
         ...RichTextTitleCopyImage
        }
      }
    }
    educationalApproach: contentfulGlobal(
      title: { eq: "Educational Approach" }
    ) {
      ...NodeGlobalFields
    }
    workerDivider: contentfulAsset(title: { eq: "Worker Divider" }) {
      title
      gatsbyImageData
    }
    ourProcess: allContentfulTitleCopyImage(
      filter: { section: { eq: "our process" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          ...RichTextTitleCopyImage
        }
      }
    }
    testimonials: allContentfulTitleCopyImage(
      filter: { section: { eq: "testimonials" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
         ...RichTextTitleCopyImage
        }
      }
    }
    houseDivider: contentfulAsset(title: { eq: "House Divider" }) {
      title
      gatsbyImageData
    }
    download: contentfulGlobal(title: { eq: "Download Our Brochure" }) {
      ...NodeGlobalFields
    }
    downloadPreview: contentfulAsset(title: { eq: "PDF Preview" }) {
      title
      gatsbyImageData
    }
    downloadBG: contentfulAsset(title: { eq: "Download BG" }) {
      title
      gatsbyImageData
    }
    csrInfo: contentfulGlobal(title: { eq: "Company Info" }) {
      ...NodeGlobalFields
    }
  }
`;
