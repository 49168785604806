import * as React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../../../common/Section/SectionTitle";
import Desktop from "./DesktopTable/DesktopTable";
import Mobile from "./MobileTable/MobileTable";
import get from "lodash/get";

const Wrap = styled.div`
  position: relative;
  padding-bottom: 3rem;
  .bg-wrap {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
  @media only screen and (max-width: 990px) {
    padding-bottom: 0;
  }
`;

const WrapCont = styled(Container)`
  position: relative;
  z-index: 10;
`;

const SelectingMaterial = ({ materials }) => {
  const bg = useStaticQuery(graphql`
    query {
      image: contentfulAsset(title: { eq: "Material BG" }) {
        title
        gatsbyImageData
      }
    }
  `);
  const fluid = get(bg, `image.gatsbyImageData`);
  const bgTitle = bg.image && bg.image.title ? bg.image.title : null;
  return (
    <Wrap>
      <div className="bg-wrap">
        {fluid ? <GatsbyImage image={fluid} alt={bgTitle} /> : null}
      </div>
      <WrapCont>
        <Row>
          <Col>
            <Title title="Selecting a Material" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Desktop materials={materials} />
            <Mobile materials={materials} />
          </Col>
        </Row>
      </WrapCont>
    </Wrap>
  );
};

export default SelectingMaterial;
