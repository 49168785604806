import React from 'react'
import styled from 'styled-components'
import Stars from '../Stars'
import { IoIosArrowForward } from 'react-icons/io'
import { WmkLink } from 'wmk-lib'
import Theme from '../../../../../vars/ThemeOptions'
 
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  border-bottom: 1px solid white;
  color: white;
  .ratings-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    .rating {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 5rem;
      margin: 1rem 2vw 0 2vw;
      p {
        color: white;
      }      
      p:first-of-type {
        margin-bottom: 2px;
      }
    }
  }
  .value-learn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    .value-wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-right: 15vw;
      p {
        color: white;
      }  
      p:first-of-type {
        margin-bottom: 2px;
      }
    }
    a {
      white-space: nowrap;
      color: white;
      padding: 5px 30px 5px 20px;
      border-radius: 100px;
      background: ${Theme.hex('primary')};
      &:hover {
        text-decoration: none;
      }
      span {
        position: relative;
        top: -2px;
        color: black;
        * {
          font-size: 1.6rem;
          margin-right: -17px;
        }
      }
    }
  }
`
 
const Row = ({ data }) => {
  const { name, averageCost, value, durability, maintenance, warranty, slug } = data ? data : null
  return (
    <Wrap>
      <div className="name-wrap">
        {name}
      </div>
      <div className="ratings-wrap">
        <div className="rating">
          <p>Average Cost</p>
          <p>{averageCost}</p>
        </div>
        <div className="rating">
          <p>Durability</p>
          <Stars rating={durability} />
        </div>
        <div className="rating">
          <p>Maintenance</p>
          <p>{maintenance}</p>
        </div>
        <div className="rating">
          <p>Warranty</p>
          <Stars rating={warranty} />
        </div>
      </div>
      <div className="value-learn-wrap">
        <div className="value-wrap">
          <p>VALUE</p>
          <Stars rating={value} white />
        </div>
        <div className="learn-wrap">
        <WmkLink to={`/`+slug} >LEARN MORE <span><IoIosArrowForward/><IoIosArrowForward/><IoIosArrowForward/></span></WmkLink>
        </div>
      </div>
    </Wrap>
  )
}
 
export default Row