import React, { useState } from 'react'
import { WmkLink } from 'wmk-lib'
import styled from 'styled-components'
import Stars from '../Stars'
import { IoIosArrowForward } from 'react-icons/io'
import Theme from '../../../../../vars/ThemeOptions'
 
const WrapRow = styled.tr`
  border-radius: 100px;
  td {
    padding: 12px 0;
  }
  td:first-of-type {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  td:last-of-type {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    width: 13rem;
    padding-right: 2rem;
  }
  td:nth-of-type(2) {
    padding-right: 3rem;
    width: 12rem;
  }
  td:not(:first-of-type) {
    // padding-right: 1rem;
  }
  td:not(:last-of-type, :first-of-type) {
    border-top: 1px solid white;
  }
  a {
    white-space: nowrap;
    color: white;
    opacity: 0;
    &:hover {
      text-decoration: none;
    }
    &.show {
      opacity: 1;
    }
    span {
      position: relative;
      top: -2px;
      color: black;
      * {
        font-size: 1.6rem;
        margin-right: -17px;
      }
    }
  }
  &.hovered {
    td {
      background: ${Theme.hex('primary')};
      border-top: none;
    }
  }
`
 
const Row = ({ data }) => {
  const [hovered, setHovered] = useState(false)
  const { name, averageCost, value, durability, maintenance, warranty, slug } = data ? data : null
  return (
    <WrapRow
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={hovered ? "hovered" : ""}
    >
      <td className="spacer" />
      <td>
        {name}
      </td>
      <td align="center">
        {averageCost}
      </td>
      <td align="center">
        <Stars rating={value} white={hovered} />
      </td>
      <td align="center">
        <Stars rating={durability} white={hovered} />
      </td>
      <td align="center">
        {maintenance}
      </td>
      <td align="center">
        <Stars rating={warranty} white={hovered} />
      </td>
      <td align="center">
        <WmkLink to={`/material/`+slug} className={hovered ? "show" : ""} >LEARN MORE <span><IoIosArrowForward/><IoIosArrowForward/><IoIosArrowForward/></span></WmkLink>
      </td>
    </WrapRow>
  )
}
 
export default Row