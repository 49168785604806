import * as React from "react";
import styled from "styled-components";
import get from "lodash/get";
import SectionTitle from "../../common/Section/SectionTitle";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Theme from "../../../vars/ThemeOptions";
import { RichText } from "../../../classes/RichText";

const Wrap = styled.div`
  * {
    transition: all 0.3s ease;
  }
  padding: 2rem 0;
  b,
  ol {
    color: ${Theme.hex("primary")};
    font-weight: bold;
  }
  p {
    margin-bottom: 10px;
  }
`;

const HowToEnter = ({ data }) => {
  // console.log("data: ", data);
  const richText1 = new RichText(get(data, "richText1"));
  const fullJson = get(richText1, "json");
  const title = get(fullJson, "content[0].content[0].value");
  const jsonWithoutSecTitle = get(richText1, "json");
  jsonWithoutSecTitle.content.shift();
  return (
    <Wrap>
      <SectionTitle title={title} titleColor="black" bold />
      {documentToReactComponents(jsonWithoutSecTitle)}
    </Wrap>
  );
};

export default HowToEnter;
