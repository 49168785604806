import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { INLINES } from "@contentful/rich-text-types";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import get from "lodash/get";
import Theme from "../../../vars/ThemeOptions";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";

const Wrap = styled.div`
  position: relative;
  z-index: 2;
  background: ${Theme.hex("primary")};
  color: ${Theme.hex("white")};
  border-top: 15px solid ${Theme.hex("darkBlue")};
  border-bottom: 15px solid ${Theme.hex("darkBlue")};
  // padding-top: 3rem;
  // padding-bottom: 3rem;
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 991px) {
    padding-top: 25rem;
    top: -25rem;
    margin-bottom: -25rem;
    z-index: 0;
  }
  @media only screen and (max-width: 550px) {
    padding-top: 28rem;
    top: -28rem;
    margin-bottom: -28rem;
  }
  @media only screen and (max-width: 460px) {
    padding-top: 30rem;
    top: -30rem;
    margin-bottom: -30rem;
  }
`;
const CopyCol = styled(Col)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.4rem;
  p {
    margin-bottom: 0;
    &.darkBlue {
      color: ${Theme.hex("darkBlue")};
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    * {
      width: 75%;
    }
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 3rem;
  }
`;
const GetQuoteLink = styled.a`
  display: inline-block;
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1.75rem;
  white-space: nowrap;
  background: ${Theme.hex("darkBlue")};
  color: ${Theme.hex("white")};
  border: 5px solid ${Theme.hex("white")};
  transition: all 0.3s ease;
  &:hover {
    text-decoration: none;
    background: ${Theme.hex("white")};
    color: ${Theme.hex("darkBlue")};
    border: 5px solid ${Theme.hex("darkBlue")};
    transition: all 0.3s ease;
  }
`;
const HeadshotWrap = styled.div`
  position: relative;
  height: 100%;
  .gatsby-image-wrapper {
    position: absolute !important;
    left: 0;
    bottom: 0;
    width: 175%;
  }
  @media only screen and (max-width: 991px) {
    .gatsby-image-wrapper {
      width: 140%;
    }
  }
`;

const DavidDembickiCTA = (props) => {
  const data = useStaticQuery(query).cta;
  // console.log('david cta data: ', data);
  const ctaLink = get(data, "richText1");
  const ctaCopyPiece1 = get(data, "shortText1");
  const ctaCopyPiece2 = get(data, "shortText2");
  const ctaCopyPiece3 = get(data, "shortText3");
  const ctaCopyPiece4 = get(data, "shortText4");
  const headshot = get(data, "fileRef");
  const hs = get(headshot, `gatsbyImageData`);
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <GetQuoteLink href={node.data.uri}>
          {node.content[0].value}
        </GetQuoteLink>
      )
    }
  };
  const rt = new RichText(ctaLink);
  return (
    <Wrap>
      <Container>
        <Row>
          <CopyCol lg={6} xl={7}>
            <p>
              {ctaCopyPiece1}
              <span>
                <b>{` ` + ctaCopyPiece2 + ` `}</b>
              </span>
              {ctaCopyPiece3}
            </p>
            <p className="darkBlue">
              <b>{ctaCopyPiece4}</b>
            </p>
          </CopyCol>
          <Col xs={8} lg={3} xl={3} className="centered">
            <RichTextReact content={rt} options={options} />
          </Col>
          <Col xs={4} lg={3} xl={2}>
            <HeadshotWrap>
              {hs ? <GatsbyImage image={hs} alt={headshot.title} /> : null}
            </HeadshotWrap>
          </Col>
        </Row>
      </Container>
    </Wrap>
  );
};

export default DavidDembickiCTA;

const query = graphql`
  query {
    cta: contentfulGlobal(title: { eq: "CTA - David Dembicki" }) {
      ...NodeGlobalFields
    }
  }
`;
