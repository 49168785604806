import * as React from "react";
import styled from "styled-components";
import Section from "../../common/Section/Section";
import Theme from "../../../vars/ThemeOptions";
import { INLINES } from "@contentful/rich-text-types";
import get from "lodash/get";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";

const Wrap = styled.div`
  .content-wrap {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    p {
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: 1.2rem;
    }
    a {
      background: ${Theme.hex("primary")};
      color: white;
      padding: 4px 75px;
      font-size: 1.5rem !important;
      font-weight: bold;
      transition: opacity 0.3s ease;
      text-decoration: none;
      &:hover {
        opacity: 0.9;
        transition: opacity 0.3s ease;
      }
    }
  }
`;

const InterestedSection = ({ data }) => {
  const { richText1 } = data ? data : { data: null };
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        // console.log(node, children);
        return (
          <a href={get(node, `data.uri`)} className="quote-cta">
            {children}
          </a>
        );
      }
    }
  };
  const rt = new RichText(richText1);
  return (
    <Wrap>
      <Section data={data} bgFade padding="1rem 0 3rem">
        <div className="content-wrap">
          <RichTextReact content={rt} options={options} />
        </div>
      </Section>
    </Wrap>
  );
};

export default InterestedSection;
