import * as React from "react";
import styled from "styled-components";
import get from "lodash/get";
import SectionTitle from "../../common/Section/SectionTitle";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";

const Wrap = styled.div`
  * {
    transition: all 0.3s ease;
  }
  img {
    width: 100%;
  }
`;

const ThreeLittlePigs = ({ data }) => {
  //console.log("data: ", data);
  const richText1 = get(data, "richText1");
  const json = get(richText1, "json");
  const titleCopy = get(json, "content[0].content[0].value");
  const fileRef = get(data, "fileRef");
  const fluid = get(fileRef, "gatsbyImageData");
  const title = get(fileRef, "title");
  const rt = new RichText(richText1);
  return (
    <Wrap>
      <SectionTitle copy={titleCopy} titleColor="black" bold />
      <RichTextReact content={rt} />
      {fluid ? <GatsbyImage image={fluid} alt={title} /> : null}
    </Wrap>
  );
};

export default ThreeLittlePigs;
