import * as React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Title from "../../common/Section/SectionTitle";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";
import get from "lodash/get";

const WrapCont = styled(Container)`
  position: relative;
  // top: -10vw;
`;

const IconTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image-wrap {
    // height: 7rem;
    // width: 7rem;
    text-align: center;
    &.icon-1 {
      height: 7rem;
      width: 7rem;
      margin-bottom: 1rem;
    }
    &.icon-2 {
      height: 7rem;
      width: 5.4rem;
      margin-bottom: 1rem;
    }
    &.icon-3 {
      height: 5.2rem;
      width: 8.3rem;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    &.icon-4 {
      height: 6rem;
      width: 7rem;
      margin-bottom: 2.25rem;
    }
    &.icon-5 {
      height: 6rem;
      width: 9.5rem;
      margin-bottom: 2.25rem;
    }
    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }
  }
  p {
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.7rem;
  }
`;

const OurProcess = ({ data }) => {
  return (
    <WrapCont>
      <Title title="Our Process" />
      <br style={{ margin: "8rem 0", display: "block" }} />
      <Row>
        <Col xs={12} lg={1} />
        {data.map((piece, i) => {
          const gatsbyImageData = get(piece, `image.gatsbyImageData`);
          const alt = piece.image ? piece.image.title : null;
          const rt = new RichText(get(piece, `copy`));
          return (
            <Col
              xs={i === data.length - 1 ? 12 : 6}
              lg={2}
              className="centered"
              key={piece.title + i}>
              <IconTextWrap>
                <div className={`image-wrap icon-` + (i + 1)}>
                  {gatsbyImageData ? (
                    <GatsbyImage image={gatsbyImageData} alt={alt} />
                  ) : null}
                </div>

                <RichTextReact content={rt} />
              </IconTextWrap>
            </Col>
          );
        })}
        <Col xs={12} lg={1} />
      </Row>
    </WrapCont>
  );
};

export default OurProcess;
