import * as React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Row, Col } from "react-bootstrap";
import Section from "../../common/Section/Section";
import Theme from "../../../vars/ThemeOptions";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";
import get from "lodash/get";

const headHeight = "150px";

const Wrap = styled.div`
  position: relative;
  top: -${headHeight};
  margin-bottom: -${headHeight};
  color: #707070;
  overflow: hidden;
  .copy {
    h6 {
      color: ${Theme.hex("primary")};
      font-size: 1.25rem;
      margin: 1.5rem 0 1rem;
    }
  }
  .hang-lights-offer {
    display: flex;
    align-items: center;
    position: relative;
    height: 5rem;
    color: white;
    margin: 0 0 1rem;
    .text-wrap {
      position: relative;
      z-index: 10;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      background: #464b5d;
      clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
      p {
        margin-bottom: 0;
        width: 95%;
      }
    }
    .offer-bg {
      height: 100%;
      width: 50vw;
      background: #464b5d;
      position: absolute;
      z-index: 5;
      top: 0;
      left: -30vw;
      clip-path: polygon(0% 0%, 97.5% 0%, 100% 50%, 97.5% 100%, 0% 100%);
    }
  }
  .cta {
    margin: 3rem 0;
    text-transform: uppercase;
    position: relative;
    h2 {
      font-weight: 700;
      margin-bottom: 2rem;
      color: black;
    }
    a.text-link {
      position: relative;
      color: white;
      font-size: 1.3rem;
      font-weight: 300;
      background: ${Theme.hex("primary")};
      padding: 2rem 8rem 1.75rem;
      width: 30rem;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      text-decoration: none;
      transition: opacity 0.3s ease;
      .gatsby-image-wrapper {
        position: absolute !important;
        &.big-mtoe {
          z-index: 2;
          top: -43px;
          right: -2px;
          width: 5.5rem;
        }
        &.small-mtoe {
          z-index: 1;
          top: 6px;
          right: -34px;
          width: 4rem;
        }
      }
      &:hover {
        opacity: 0.8;
        transition: opacity 0.3s ease;
      }
      p {
        margin-bottom: 0 !important;
      }
      b {
        font-size: 2rem;
        font-weight: 700;
        margin-left: 0.75rem;
        margin-bottom: 0 !important;
        white-space: nowrap;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    top: -175px;
    a.text-link {
      width: 21rem !important;
      margin-top: 3rem !important;
    }
  }
`;

const WinterSavingsContent = ({ data, mtoe, sflake }) => {
  const {
    richText1,
    richText2,
    shortText1,
    shortText2,
    shortText3,
    shortText4
  } = data ? data : { data: null };
  const image = get(mtoe, `gatsbyImageData`);
  const rt1 = new RichText(richText1);
  const rt2 = new RichText(richText2);
  return (
    <Wrap>
      <Section
        data={data}
        titleColor="black"
        titleSize="2.5rem"
        bgFade
        fadeColor="white"
        padding={headHeight + ` 0 5rem 0`}
        boldTitle={true}>
        <Row>
          <Col lg={7} xs={12}>
            <div className="copy">
              <RichTextReact content={rt1} />
            </div>
            <div className="hang-lights-offer">
              <div className="text-wrap">
                <RichTextReact content={rt2} />
              </div>
              <div className="offer-bg" />
            </div>
            <p>{shortText1 && shortText1}</p>
            {shortText2 && shortText3 && shortText4 && (
              <div className="cta">
                <h2>{shortText2}</h2>
                <a
                  className="text-link"
                  href={shortText4}
                  target="_blank"
                  rel="noopener noreferrer">
                  <b>{shortText3}</b>
                  {image ? (
                    <GatsbyImage
                      className="big-mtoe"
                      image={image}
                      alt={mtoe.title}
                    />
                  ) : null}
                  {image ? (
                    <GatsbyImage
                      className="small-mtoe"
                      image={image}
                      alt={mtoe.title}
                    />
                  ) : null}
                </a>
              </div>
            )}
          </Col>
        </Row>
      </Section>
    </Wrap>
  );
};

export default WinterSavingsContent;
