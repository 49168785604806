import * as React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import Title from "../../../common/Section/SectionTitle";
import Condition from "./Condition";
import SlidingWorkers from "./SlidingWorkers";
import Theme from "../../../../vars/ThemeOptions";
import get from "lodash/get";
import { RichTextReact } from "../../../RichTextReact/RichTextReact";
import { RichText } from "../../../../classes/RichText";

const Wrap = styled.div``;

const WrapCont = styled(Container)`
  position: relative;
  .intro-wrap {
    width: 70%;
    position: relative;
    z-index: 10;
  }
  p {
    color: ${Theme.hex("grayText")};
    font-size: 0.95rem;
    margin-bottom: 1.5rem;
  }
`;

const Hero = ({ title, introText, conditions }) => {
  const rt = new RichText(introText);
  return (
    <Wrap>
      <WrapCont>
        <SlidingWorkers />
        <Title title={title} />
        <div className="intro-wrap">
          <RichTextReact content={rt} />
        </div>
        {conditions.map((condition, i) => (
          <Condition
            condition={condition}
            color={i === 0 ? "green" : i === 1 ? "yellow" : "red"}
            key={get(condition, `title`) + i}
          />
        ))}
      </WrapCont>
    </Wrap>
  );
};

export default Hero;
