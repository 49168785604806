import * as React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import get from "lodash/get";
import { graphql, useStaticQuery } from "gatsby";
import ResourceTiles from "./ResourceTiles";
import { RichText } from "../../../classes/RichText";
import { RichTextReact } from "../../RichTextReact/RichTextReact";

const MainWrap = styled.div`
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    max-width: 50rem;
  }
`;

const ThankYouPage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulGlobal(title: { eq: "Thank You" }) {
        ...NodeGlobalFields
      }
    }
  `).contentfulGlobal;
  console.log(data);
  const title = get(data, "title");
  const rt = new RichText(get(data, "richText1"));
  return (
    <Container>
      <MainWrap>
        <h2>{title}</h2>
        <RichTextReact content={rt} />
      </MainWrap>
      <ResourceTiles />
    </Container>
  );
};

export default ThankYouPage;
