import * as React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import get from "lodash/get";
import Theme from "../../../../vars/ThemeOptions";
import { RichTextReact } from "../../../RichTextReact/RichTextReact";
import { RichText } from "../../../../classes/RichText";

const WrapRow = styled(Row)`
  .gatsby-image-wrapper {
    margin-bottom: 2rem;
  }
  &.green {
    .gatsby-image-wrapper {
      border-bottom: 12px solid ${Theme.hex("primary")};
    }
    h3 {
      color: ${Theme.hex("primary")};
    }
  }
  &.yellow {
    .gatsby-image-wrapper {
      border-bottom: 12px solid ${Theme.hex("yellow")};
    }
    h3 {
      color: ${Theme.hex("yellow")};
    }
  }
  &.red {
    .gatsby-image-wrapper {
      border-bottom: 12px solid ${Theme.hex("red")};
    }
    h3 {
      color: ${Theme.hex("red")};
    }
  }
`;

const Condition = ({ condition, color }) => {
  const fluid = get(condition, `image.gatsbyImageData`);
  const alt =
    condition.image && condition.image.title ? condition.image.title : null;
  const rt = new RichText(get(condition, `copy`));
  return (
    <WrapRow className={color}>
      <Col xs={12} lg={4}>
        {fluid ? <GatsbyImage image={fluid} alt={alt} /> : null}
      </Col>
      <Col xs={12} lg={5}>
        <h3>{condition.title}</h3>

        <RichTextReact content={rt} />
      </Col>
    </WrapRow>
  );
};

export default Condition;
