import { INLINES } from "@contentful/rich-text-types";
import { get } from "lodash";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Theme from "../../../vars/ThemeOptions";
import { RichTextReact } from "../../RichTextReact/RichTextReact";

const Wrap = styled.div``;
const ContentCol = styled(Col)`
  padding-top: 1rem;
  p {
    padding-right: 20%;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  h3 {
    color: ${Theme.hex("primary")};
    font-weight: 200;
    font-style: italic;
  }
  a {
    display: inline-block;
    background: #151232;
    color: white;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 15px;
    margin: 10px 0 5px;
    &:hover {
      text-decoration: none;
    }
  }
`;

const CopyAndCta = ({ data, route }) => {
  // console.log('data: ', data);
  const json = get(data, "richText1");
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        // console.log('node: ', node);
        const copy = get(node, "content[0].value");
        const href = get(node, "data.uri");
        return (
          <a href={href} className={route}>
            {copy}
          </a>
        );
      }
    }
  };
  return (
    <Wrap>
      <Container>
        <Row>
          <ContentCol>
            <RichTextReact content={json} options={options} />
          </ContentCol>
        </Row>
      </Container>
    </Wrap>
  );
};

export default CopyAndCta;
