import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../../common/Section/SectionTitle";
import { WmkLink } from "wmk-lib";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import get from "lodash/get";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";

const Wrap = styled.div`
  // padding-top: 5rem;
  // margin-bottom: 2rem;
  position: relative;
  top: 5rem;
  height: 40rem;
  width: 100vw;
  .bg-wrap {
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0;
    height: 140%;
    width: 100%;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
  @media only screen and (min-width: 1950px) {
    .bg-wrap {
      .gatsby-image-wrapper {
        height: auto;
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 990px) {
    top: 0;
  }
`;

const WrapCont = styled(Container)`
  position: relative;
  z-index: 10;
  .centered {
    padding-bottom: 2rem;
    text-align: center;
    p {
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: left;
    }
    a {
      position: relative;
      z-index: 10;
      top: 1rem;
      padding: 15px 100px;
      font-weight: bold;
      background: #13182f;
      color: white;
      transition: background 0.3s ease;
      &:hover {
        text-decoration: none;
        background: #374891;
        transition: background 0.3s ease;
      }
    }
    .gatsby-image-wrapper {
      position: relative;
      top: -10rem;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .centered:first-of-type {
      a {
        top: 25rem;
      }
    }
    .centered:last-of-type {
      padding-left: 27%;
      margin-top: 9rem;
      .gatsby-image-wrapper {
        width: 65%;
      }
    }
    .gatsby-image-wrapper {
      top: -13rem !important;
    }
  }
  @media only screen and (max-width: 767px) {
    .centered:first-of-type {
      a {
        top: 19rem;
        padding: 15px 60px;
      }
    }
    .centered:last-of-type {
      padding-left: 27%;
      margin-top: 9rem;
      .gatsby-image-wrapper {
        width: 65%;
      }
    }
    .centered {
      p {
        margin-top: 0;
      }
    }
    .gatsby-image-wrapper {
      top: -13rem !important;
    }
  }
`;

const Download = ({ data, preview, bg, gtm }) => {
  const [dataLayerObj, setDataLayerObj] = useState();
  const previewFluid = get(preview, `gatsbyImageData`);
  const previewAlt = preview && preview.title ? preview.title : null;
  const bgFluid = get(bg, `gatsbyImageData`);
  const bgAlt = bg && bg.title ? bg.title : null;
  useEffect(() => {
    const globalDataLayer = window.dataLayer;
    if (globalDataLayer && typeof globalDataLayer === "object") {
      setDataLayerObj(globalDataLayer);
    }
  }, [gtm]);
  const rt = new RichText(data.richText1);
  return (
    <Wrap>
      <WrapCont>
        <Title title={data.title} />
        <Row>
          <Col xs={12} lg={6} className="centered">
            <RichTextReact content={rt} />
            <WmkLink
              onClick={
                dataLayerObj && gtm && typeof gtm === "object"
                  ? () => {
                      dataLayerObj.push(gtm);
                    }
                  : undefined
              }
              to={data.fileRef.file.url}
              target="_blank">
              DOWNLOAD
            </WmkLink>
          </Col>
          <Col xs={12} lg={6} className="centered">
            {previewFluid ? (
              <GatsbyImage image={previewFluid} alt={previewAlt} />
            ) : null}
          </Col>
        </Row>
      </WrapCont>
      <div className="bg-wrap">
        {bgFluid ? <GatsbyImage image={bgFluid} alt={bgAlt} /> : null}
      </div>
    </Wrap>
  );
};

export default Download;
