// NOTE: This component is made to work with Global fields, which are what is used for each section in this project's content models
// To use, simply pass the section data as a prop and add whatever children you would like.
// There are some props set up to customize title text color, title text size, and whether or not you want the gradient over the bg image (can use gradient even if there is no bg image)

import * as React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import Title from "./SectionTitle";
import { GatsbyImage } from "gatsby-plugin-image";
import get from "lodash/get";
import { Img } from "wmk-image";

const Styled: { [key: string]: Function } = {};
Styled.Wrap = styled.div<{ padding: string }>`
  position: relative;
  padding: ${({ padding }) => (padding ? padding : "1rem 0")};
`;

Styled.WrapCont = styled(Container)`
  position: relative;
  z-index: 1;
`;

Styled.BgImage = styled.div<{ bgFade: string; fadeColor: string }>`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
  }
  .bg-fade {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${({ bgFade, fadeColor }) =>
      bgFade
        ? fadeColor === "white"
          ? "linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 60%, rgba(255,255,255,0) 90%)"
          : "linear-gradient(to right, rgba(19,24,47,1) 0%, rgba(19,24,47,0.7) 100%)"
        : "none"};
  }
  @media only screen and (max-width: 991px) {
    .bg-fade {
      background: ${({ bgFade, fadeColor }) =>
        bgFade
          ? fadeColor === "white"
            ? "linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 90%, rgba(255,255,255,0.75) 100%)"
            : "linear-gradient(to right, rgba(19,24,47,1) 0%, rgba(19,24,47,0.7) 100%)"
          : "none"};
    }
  }
`;

type SectionProps = {
  children: React.ReactNode;
  data: object;
  titleColor?: string;
  titleSize?: string;
  noBgImage?: boolean;
  bgFade?: boolean;
  fadeColor?: string;
  padding?: string;
  boldTitle?: string;
  mobTitleColor?: string;
};

const Section = ({
  children,
  data,
  titleColor,
  titleSize,
  noBgImage,
  bgFade,
  fadeColor,
  padding,
  boldTitle,
  mobTitleColor
}: SectionProps) => {
  const title = get(data, `title`);
  const image = get(data, `fileRef.gatsbyImageData`);
  const alt = get(data, `fileRef.title`);
  return (
    <Styled.Wrap padding={padding}>
      <Styled.WrapCont>
        <Title
          title={title}
          titleColor={titleColor}
          mobTitleColor={mobTitleColor}
          titleSize={titleSize}
          bold={boldTitle}
        />
        {children}
      </Styled.WrapCont>
      {noBgImage ? null : (
        <Styled.BgImage bgFade={bgFade} fadeColor={fadeColor}>
          {image ? <GatsbyImage image={image} alt={alt} /> : null}
          {bgFade && <div className="bg-fade" />}
        </Styled.BgImage>
      )}
    </Styled.Wrap>
  );
};

export default Section;
