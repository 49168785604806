import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import get from "lodash/get";
import PigHero from "./PigHero";
import ThreeLittlePigs from "./ThreeLittlePigs";
import HowToEnter from "./HowToEnter";
import DoubleChances from "./DoubleChances";
//import PinkPigPromoForm from "./Form";
//import CsrLpForm from "../LPHomeShow/Form";
import {
  howDidHearOptions,
  PigFormWrap,
  //serviceInterests,
  sidingTypeOptions
} from "./Form";
import {
  serviceInterestOptions,
  whenToStartOptions
} from "../LPHomeShow/LPHomeShow";
import { NetlifyForm } from "wmk-lib";

const Wrap = styled.div`
  * {
    transition: all 0.3s ease;
  }
  padding: 2rem 0;
`;

const LPPigPromo = (props) => {
  const data = useStaticQuery(graphql`
    query {
      heroData: contentfulGlobal(title: { eq: "LP Pink Pig - Hero Content" }) {
        ...NodeGlobalFields
      }
      threeLittlePigsData: contentfulGlobal(
        title: { eq: "LP Pink Pig - Sec 1 - Three Little Pigs" }
      ) {
        ...NodeGlobalFields
      }
      howToEnterData: contentfulGlobal(
        title: { eq: "LP Pink Pig - Sec 2 - How To Enter" }
      ) {
        ...NodeGlobalFields
      }
      doubleChancesData: contentfulGlobal(
        title: { eq: "LP Pink Pig - Sec 3 - Double Your Chances" }
      ) {
        ...NodeGlobalFields
      }
    }
  `);
  //console.log(data)
  const heroData = get(data, "heroData");
  const threeLittlePigsData = get(data, "threeLittlePigsData");
  const howToEnterData = get(data, "howToEnterData");
  const doubleChancesData = get(data, "doubleChancesData");
  return (
    <Wrap>
      <PigHero data={heroData} />
      <Container>
        <Row>
          <Col
            md={{ span: 12, order: 1 }}
            lg={{ span: 6, order: 3 }}
            style={{ position: "relative" }}>
            <PigFormWrap>
              <NetlifyForm
                title={() => (
                  <h2>
                    ENTER TO WIN A <span>PINK PIG</span> TRAEGER SMOKER AND
                    GRILL
                  </h2>
                )}
                config={{
                  postUrl: "/why-choose-us",
                  name: "pink_pig_promo"
                }}
                fields={[
                  { as: `name` },
                  { as: `email` },
                  { as: `phone` },
                  { as: `address` },
                  {
                    as: "select",
                    props: {
                      label: "Which services are you interested in?",
                      options: serviceInterestOptions,
                      name: "fullOrPartial"
                    }
                  },
                  {
                    as: "select",
                    props: {
                      name: "howDidYouHearAboutUs",
                      label: "How did you hear about us?",
                      options: howDidHearOptions
                    }
                  },
                  {
                    as: "select",
                    props: {
                      name: "timeline",
                      label: "How soon do you want to start?",
                      options: whenToStartOptions
                    }
                  },
                  {
                    as: `select`,
                    props: {
                      name: `desiredSidingType`,
                      label: `Select a Siding Type`,
                      options: sidingTypeOptions,
                      isRequired: true
                    }
                  },
                  {
                    as: `checkbox`,
                    props: {
                      name: `financing`,
                      label: `Interested in Financing?`,
                      text: `Yes, send me information about applying for financing.`
                    }
                  },
                  { as: `message` }
                ]}
              />
            </PigFormWrap>
          </Col>
          <Col md={{ span: 12, order: 3 }} lg={{ span: 1, order: 2 }}></Col>
          <Col md={{ span: 12, order: 2 }} lg={{ span: 5, order: 1 }}>
            <ThreeLittlePigs data={threeLittlePigsData} />
            <HowToEnter data={howToEnterData} />
            <DoubleChances data={doubleChancesData} />
          </Col>
        </Row>
      </Container>
    </Wrap>
  );
};

export default LPPigPromo;
