import * as React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Hero from "./Hero";
import EveryWindowSec from "./EveryWindow/EveryWindow";
import EnergyEfficientSec from "./EnergyEfficient";
import IdentifyingWindowNeedsSec from "./IdentifyingWindowNeeds";
import OurProcessSec from "../IdentifyingNeedsPage/OurProcess";
import InterestedSec from "./Interested";

const Wrap = styled.div`
  color: #707070;
`;

const WhyChooseUsPage = () => {
  const data = useStaticQuery(query);
  const {
    heroData,
    everyWindowSecData,
    energyEfficientData,
    identifyingWindowNeedsData,
    fixedPricing,
    eduApproach,
    ourProcessData,
    interestedData,
  } = data;

  return (
    <Wrap>
      <Hero data={heroData} />
      <EveryWindowSec data={everyWindowSecData} />
      <EnergyEfficientSec data={energyEfficientData} />
      <IdentifyingWindowNeedsSec
        data={identifyingWindowNeedsData}
        fixedPricing={fixedPricing}
        eduApproach={eduApproach}
      />
      <OurProcessSec data={ourProcessData.edges.map((p) => p.node)} />
      <InterestedSec data={interestedData} />
    </Wrap>
  );
};

export default WhyChooseUsPage;

// NOTE: I will come back to this and rework the data + queries so that we don't need to query based off of the exact title, but this page needs to be done asap so I'm finishing it as-is before reworking the data + queries

const query = graphql`
  query {
    heroData: contentfulGlobal(title: { eq: "Why Choose Us?" }) {
      ...NodeGlobalFields
    }
    everyWindowSecData: contentfulGlobal(
      title: { eq: "We Have Window Styles To Fit Every Home" }
    ) {
      ...NodeGlobalFields
    }
    energyEfficientData: contentfulGlobal(
      title: { eq: "Energy Efficient Windows Can Literally Pay for Themselves" }
    ) {
      ...NodeGlobalFields
    }
    identifyingWindowNeedsData: contentfulGlobal(
      title: { eq: "Identifying Your Window Needs" }
    ) {
      ...NodeGlobalFields
    }
    fixedPricing: contentfulTitleCopyImage(title: { eq: "Fixed Pricing" }) {
      image {
        gatsbyImageData(height: 75)
      }
      title
    }
    eduApproach: contentfulTitleCopyImage(
      title: { eq: "Educational Approach" }
    ) {
      image {
        gatsbyImageData(height: 75)
      }
      title
    }
    ourProcessData: allContentfulTitleCopyImage(
      filter: { section: { eq: "our process" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
         ...RichTextTitleCopyImage
        }
      }
    }
    interestedData: contentfulGlobal(
      title: { eq: "Interested in Our Window Services?" }
    ) {
      ...NodeGlobalFields
    }
  }
`;
