import * as React from "react";
import styled from "styled-components";
import { IoIosStar, IoIosStarHalf, IoIosStarOutline } from "react-icons/io";
import Theme from "../../../../vars/ThemeOptions";

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: ${Theme.hex("primary")};
  font-size: 1.2rem;
  &.white {
    color: white;
  }
`;

const Stars = ({ rating, white }) => {
  const fullStars =
    rating.toString().length === 1
      ? rating
      : parseInt(rating.toString().slice(0, 1));
  const fullStarsRender = [];
  for (let i = 0; i < fullStars; i++) {
    fullStarsRender.push(<IoIosStar key={'fullStar'+i}/>);
  }
  const halfStar = rating.toString().length === 1 ? 0 : 1;
  const emptyStars = 4 - fullStars - halfStar;
  const emptyStarsRender = [];
  for (let j = 0; j < emptyStars; j++) {
    emptyStarsRender.push(<IoIosStarOutline key={'outlStar'+j}/>);
  }
  return (
    <Wrap className={white ? "white" : ""}>
      {fullStarsRender.map((star, i) => {
        return star;
      })}
      {halfStar === 1 ? <IoIosStarHalf /> : null}
      {emptyStarsRender.map((star, i) => {
        return star;
      })}
    </Wrap>
  );
};

export default Stars;
