import React from 'react'
import styled from 'styled-components'
import Row from './Row'
 
const Wrap = styled.div`
  // color: white;
  @media only screen and (min-width: 991px) {
    display: none;
  }
`
 
const Mobile = ({ materials }) => {
  return (
    <Wrap>
      {materials.map((material, i) => {
        return <Row data={material} key={material.name + i} />
      })}
    </Wrap>
  )
}
 
export default Mobile