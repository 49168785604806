import * as React from "react";
import HomePage from "../components/routes/Home/Home";
import IdentifyingNeedsPage from "../components/routes/IdentifyingNeedsPage/IdentifyingNeedsPage";
import WhyChooseUsPage from "../components/routes/WhyChooseUs/WhyChooseUsPage";
import WinterSavingsPage from "../components/routes/WinterSavings/WinterSavings";
import LPGeofence from "../components/routes/LPGeofence/LPGeofence";
import LPFoCo from "../components/routes/LPFoCo/LPFoCo";
//import HoverLP from "../components/routes/HoverLP/HoverLP";
import LPPigPromo from "../components/routes/LPPigPromo/LPPigPromo";
import LPHomeShow from "../components/routes/LPHomeShow/LPHomeShow";
import Theme from "../vars/ThemeOptions";
import ThankYouPage from "../components/routes/ThankYou/ThankYou";
import JCCIntakeFormSuccessPage from "../components/routes/LPJCCIntakeFormPage/JCCIntakeFormSucess";
import LpJccIntakeFormPage from "../components/routes/LPJCCIntakeFormPage/JccIntakeFormPage";

export const dataHash = {
  home: {
    RenderComponent: <HomePage />
  },
  "thank-you": {
    RenderComponent: <ThankYouPage />,
    layoutProps: {
      showCta: true,
      headCtaColor: Theme.hex("primary")
    }
  },
  "identifying-your-siding-needs": {
    layoutProps: {
      logoSlug: "identifying-your-siding-needs",
      headLogoColor: Theme.hex("black"),
      headCtaColor: Theme.hex("primary"),
      footLogoColor: Theme.hex("black")
    },
    RenderComponent: <IdentifyingNeedsPage />
  },
  "why-choose-us": {
    layoutProps: {
      logoSlug: "why-choose-us",
      headLogoColor: Theme.hex("white"),
      headCtaColor: Theme.hex("white"),
      footLogoColor: Theme.hex("black"),
      showCta: true
    },
    RenderComponent: <WhyChooseUsPage />
  },
  jccintakeform: {
    layoutProps: {
      logoSlug: "jccintakeform",
      headLogoColor: Theme.hex("white"),
      showCta: true,
      headCtaStyle: "simple",
      headCtaColor: Theme.hex("white")
    },
    RenderComponent: <LpJccIntakeFormPage />
  },
  "jcc-intake-form-success": {
    layoutProps: {
      logoSlug: "jccintakeform",
      headLogoColor: Theme.hex("white"),
      showCta: true,
      headCtaStyle: "simple",
      headCtaColor: Theme.hex("white")
    },
    RenderComponent: <JCCIntakeFormSuccessPage />
  },
  "winter-savings": {
    layoutProps: {
      logoSlug: "winter-savings",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black")
    },
    RenderComponent: <WinterSavingsPage />
  },
  "geofence-1": {
    layoutProps: {
      logoSlug: "geofence-1",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black"),
      footBorderColor: "#13182F",
      footBorderThickness: "25px",
      footMobileBg: Theme.hex("white")
    },
    RenderComponent: LPGeofence
  },
  "geofence-2": {
    layoutProps: {
      logoSlug: "geofence-2",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black"),
      footBorderColor: "#13182F",
      footBorderThickness: "25px",
      footMobileBg: Theme.hex("white")
    },
    RenderComponent: LPGeofence
  },
  "geofence-3": {
    layoutProps: {
      logoSlug: "geofence-3",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black"),
      footBorderColor: "#13182F",
      footBorderThickness: "25px",
      footMobileBg: Theme.hex("white")
    },
    RenderComponent: LPGeofence
  },
  "geofence-4": {
    layoutProps: {
      logoSlug: "geofence-4",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black"),
      footBorderColor: "#13182F",
      footBorderThickness: "25px",
      footMobileBg: Theme.hex("white")
    },
    RenderComponent: LPGeofence
  },
  "geofence-5": {
    layoutProps: {
      logoSlug: "geofence-5",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black"),
      footBorderColor: "#13182F",
      footBorderThickness: "25px",
      footMobileBg: Theme.hex("white")
    },
    RenderComponent: LPGeofence
  },
  "geofence-6": {
    layoutProps: {
      logoSlug: "geofence-6",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black"),
      footBorderColor: "#13182F",
      footBorderThickness: "25px",
      footMobileBg: Theme.hex("white")
    },
    RenderComponent: LPGeofence
  },
  "geofence-7": {
    layoutProps: {
      logoSlug: "geofence-7",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black"),
      footBorderColor: "#13182F",
      footBorderThickness: "25px",
      footMobileBg: Theme.hex("white")
    },
    RenderComponent: LPGeofence
  },
  "geofence-8": {
    layoutProps: {
      logoSlug: "geofence-8",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black"),
      footBorderColor: "#13182F",
      footBorderThickness: "25px",
      footMobileBg: Theme.hex("white")
    },
    RenderComponent: LPGeofence
  },
  "geofence-noco-1": {
    layoutProps: {
      logoSlug: "geofence-noco-1",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black"),
      footBorderColor: "#13182F",
      footBorderThickness: "25px",
      footMobileBg: Theme.hex("white")
    },
    RenderComponent: LPGeofence
  },
  "geofence-noco-2": {
    layoutProps: {
      logoSlug: "geofence-noco-2",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black"),
      footBorderColor: "#13182F",
      footBorderThickness: "25px",
      footMobileBg: Theme.hex("white")
    },
    RenderComponent: LPGeofence
  },
  "geofence-noco-3": {
    layoutProps: {
      logoSlug: "geofence-noco-3",
      headLogoColor: Theme.hex("black"),
      showCta: false,
      footLogoColor: Theme.hex("black"),
      footBorderColor: "#13182F",
      footBorderThickness: "25px",
      footMobileBg: Theme.hex("white")
    },
    RenderComponent: LPGeofence
  },
  foco: {
    layoutProps: {
      logoSlug: "foco",
      headLogoColor: Theme.hex("white"),
      showCta: true,
      headCtaColor: Theme.hex("white"),
      ctaRegion: "northern-colorado",
      footLogoColor: Theme.hex("black"),
      footBorderColor: Theme.hex("darkBlue"),
      footBorderThickness: "25px",
      footMobileBg: "#13182F"
    },
    RenderComponent: <LPFoCo />
  },
  // hover: {
  //   layoutProps: {
  //     // will come when the design does
  //   },
  //   RenderComponent: <HoverLP />
  // },
  "pink-pig-promo": {
    layoutProps: {
      logoSlug: "pink-pig-promo",
      headLogoColor: Theme.hex("white"),
      showCta: true,
      headCtaStyle: "simple",
      headCtaColor: Theme.hex("white")
    },
    RenderComponent: <LPPigPromo />
  },
  "home-show": {
    layoutProps: {
      logoSlug: "pink-pig-promo",
      headLogoColor: Theme.hex("white"),
      showCta: true,
      headCtaStyle: "simple",
      headCtaColor: Theme.hex("white")
    },
    RenderComponent: <LPHomeShow />
  }
};
