import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { get } from "lodash";
import { BLOCKS } from "@contentful/rich-text-types";
import SectionTitle from "../../common/Section/SectionTitle";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";

const Wrap = styled.div`
  position: relative;
  // height: 50rem;
  overflow: hidden;
  padding-bottom: 3rem;
`;
const BgWrap = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  .parallax-outer {
    width: 100%;
    height: 100%;
    .parallax-inner {
      width: 100%;
      height: 100%;
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
  }
`;
const BgFade = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(20, 18, 49, 1) 0%,
    rgba(20, 18, 49, 0.2) 80%
  );
  @media only screen and (max-width: 991px) {
    background: linear-gradient(
      to top,
      rgba(20, 18, 49, 1) 0%,
      rgba(20, 18, 49, 0.8) 80%,
      rgba(20, 18, 49, 0) 100%
    );
  }
`;
const CopyCol = styled(Col)`
  color: white;
  padding-right: 20%;
  height: 100%;
  padding-top: 30%;
  p {
    font-size: 1.4rem;
  }
  h2 {
    font-size: 1.9rem;
    font-weight: bold;
  }
`;
const IconsRow = styled(Row)``;
const IconTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  p {
    margin-bottom: -0.5rem;
    white-space: nowrap;
  }
  .image-wrap {
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.7rem;
  }
`;

const MainContent = ({ data, backgrounds, iconData, route, hero }) => {
  // console.log('data: ', data);
  // console.log('iconData: ', iconData);
  // console.log('backgrounds: ', backgrounds);
  const contentJson = get(data, "richText1");
  const options = {
    renderNode: {
      [BLOCKS.HEADING_4]: (node) => {
        // console.log('node: ', node);
        const copy = get(node, "content[0].value");
        return <SectionTitle title={copy} titleColor="white" />;
      }
    }
  };
  let image;
  switch (route) {
    case "geofence-1":
      image = backgrounds[0];
      break;
    case "geofence-2":
      image = backgrounds[1];
      break;
    case "geofence-3":
      image = backgrounds[2];
      break;
    case "geofence-4":
      image = backgrounds[3];
      break;
    case "geofence-5":
      image = backgrounds[4];
      break;
    case "geofence-6":
      image = backgrounds[5];
      break;
    case "geofence-7":
      image = backgrounds[6];
      break;
    case "geofence-8":
      image = backgrounds[7];
      break;
    default:
      image = backgrounds[0];
      break;
  }
  const fluid = get(hero, `gatsby`, get(image, "gatsby"));
  const alt = get(image, "title");
  const rt = new RichText(contentJson);
  return (
    <Wrap>
      <BgWrap>
        <Parallax y={["-200px", "200px"]}>
          {image ? <GatsbyImage image={fluid} alt={alt} /> : null}
        </Parallax>
        <BgFade />
      </BgWrap>
      <Container>
        <Row>
          <CopyCol>
            <RichTextReact content={rt} options={options} />
          </CopyCol>
        </Row>
        <IconsRow>
          {iconData.map((piece, i) => {
            const copyJson = get(piece, "copy");
            const imgSrc = get(piece, "image.file.url");
            const imgAlt = get(piece, "image.title");
            const rt = new RichText(copyJson);
            return (
              <Col
                xs={i === iconData.length - 1 ? 12 : 6}
                lg={2}
                className="centered"
                key={piece.title + i}>
                <IconTextWrap>
                  <div className={`image-wrap icon-` + (i + 1)}>
                    <img src={imgSrc} alt={imgAlt} />
                  </div>
                  <RichTextReact content={rt} />
                </IconTextWrap>
              </Col>
            );
          })}
        </IconsRow>
      </Container>
    </Wrap>
  );
};

export default MainContent;
