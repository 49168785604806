import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import get from "lodash/get";
import styled from "styled-components";
import Content from "./Content";

const Wrap = styled.div``;

const WinterSavingsPage = (props) => {
  const data = useStaticQuery(query);
  const contentData = get(data, "contentData");
  const mistletoeImg = get(data, "mistletoe");
  return (
    <Wrap>
      <Content data={contentData} mtoe={mistletoeImg} />
    </Wrap>
  );
};

export default WinterSavingsPage;

const query = graphql`
  query {
    contentData: contentfulGlobal(title: { eq: "5% Winter Savings" }) {
      ...NodeGlobalFields
    }
    mistletoe: contentfulAsset(title: { eq: "Mistletoe Digital Art" }) {
      title
      fluid: gatsbyImageData
    }
  }
`;
