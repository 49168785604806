import * as React from "react";
import styled from "styled-components";

const WrapRow = styled.tr`
  th {
    padding-bottom: 10px;
  }
  th:not(:first-of-type) {
    text-align: center;
    width: 7rem;
  }
  .spacer {
    width: 3vw;
    visibility: hidden;
  }
`;

const Headings = (props) => {
  return (
    <WrapRow>
      <th className="spacer">.</th>
      <th>Material</th>
      <th>Average Cost</th>
      <th>Value</th>
      <th>Durability</th>
      <th>Maintenance</th>
      <th>Warranty</th>
      <th className="spacer">.</th>
    </WrapRow>
  );
};

export default Headings;
