import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import get from "lodash/get";
import Theme from "../../../vars/ThemeOptions";
import DavidCTA from "./DavidCta";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";
import { checkMarkBullets } from "../WhyChooseUs/EnergyEfficient";

const Wrap = styled.div`
  position: relative;
  top: -150px;
  margin-bottom: -150px;
  color: white;
  .list-item {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    .gatsby-image-wrapper {
      margin-right: 0.75rem;
    }
    p {
      margin: 8px 0 0 0;
    }
  }
  @media only screen and (max-width: 991px) {
    top: -165px;
  }
`;

const ContentContainer = styled(Container)`
  padding-top: 175px;
  position: relative;
  z-index: 1;
  .italics {
    font-size: 2rem;
    font-style: italic;
    font-weight: 200;
    text-transform: uppercase;
  }
`;

const BgImage = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
  }
  .bg-fade {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      to right,
      rgba(19, 24, 47, 1) 0%,
      rgba(19, 24, 47, 0.7) 100%
    );
  }
  @media only screen and (max-width: 991px) {
    .bg-fade {
      background: linear-gradient(
        to right,
        rgba(19, 24, 47, 1) 0%,
        rgba(19, 24, 47, 0.7) 100%
      );
    }
  }
`;

const CustomTitle = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  max-width: 30rem;
  h1 {
    text-transform: uppercase;
    color: ${Theme.hex("white")};
    margin-bottom: 0;
    font-weight: 400;
  }
  span {
    color: ${Theme.hex("primary")};
    font-weight: bold;
  }
  .green-bar {
    height: 100%;
    width: 25vw;
    background: ${Theme.hex("primary")};
    position: absolute;
    top: 0px;
    left: -27vw;
  }
`;

const CopyCol = styled(Col)`
  position: relative;
  top: -20px;
  .services-include {
    color: ${Theme.hex("primary")};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.25rem;
  }
  @media only screen and (max-width: 991px) {
    .services-include {
      color: ${Theme.hex("darkBlue")};
    }
  }
`;

const GetQuoteLink = styled.a`
  display: inline-block;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem 2rem;
  margin-bottom: 4rem;
  color: ${Theme.hex("white")};
  background: ${Theme.hex("darkBlue")};
  border: 5px solid #5a99b3;
  transition: all 0.3s ease;
  &:hover {
    text-decoration: none;
    color: ${Theme.hex("darkBlue")};
    background: ${Theme.hex("primary")};
    border: 5px solid ${Theme.hex("white")};
    transition: all 0.3s ease;
  }
`;

const LPFoCo = (props) => {
  const data = useStaticQuery(query);
  // console.log("foco data: ", data)
  // main content data
  const content = get(data, "content");
  const bg = get(content, "fileRef.gatsbyImageData");
  const alt = get(content, `fileRef.title`);
  const mainContent = get(content, "richText1");
  const titlePiece1 = get(content, "shortText1");
  const titlePiece2 = get(content, "shortText2");
  const titlePiece3 = get(content, "shortText3");

  const options = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: checkMarkBullets,
      [BLOCKS.QUOTE]: (node, children) => (
        <p className="services-include">{node.content[0].content[0].value}</p>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <GetQuoteLink href={node.data.uri}>
          {node.content[0].value}
        </GetQuoteLink>
      )
    },
    renderMark: {
      [MARKS.ITALIC]: (node, children) => <p className="italics">{node}</p>
    }
  };
  const rt = new RichText(mainContent);
  return (
    <>
      <Wrap>
        <ContentContainer>
          <Row>
            <Col>
              <CustomTitle>
                <h1>
                  {titlePiece1}
                  <span>{` ` + titlePiece2 + ` `}</span>
                  {titlePiece3}
                </h1>
                <div className="green-bar" />
              </CustomTitle>
            </Col>
          </Row>
          <Row>
            <CopyCol lg={9} md={12}>
              <RichTextReact content={rt} options={options} />
            </CopyCol>
          </Row>
        </ContentContainer>
        <BgImage>
          {bg ? <GatsbyImage image={bg} alt={alt} /> : null}
          {<div className="bg-fade" />}
        </BgImage>
      </Wrap>
      <DavidCTA />
    </>
  );
};

export default LPFoCo;

const query = graphql`
  query {
    content: contentfulGlobal(title: { eq: "LP FoCo" }) {
      ...NodeGlobalFields
    }
  }
`;
