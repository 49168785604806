import React, { useState } from "react";
import Section from "../../../common/Section/Section";
import Slider from "./Slider";
import get from "lodash/get";

const EveryWindowWeInstallSection = ({ data }) => {
  const [currPiece, setCurrPiece] = useState(0);

  const windows = get(data, `richText1.references`, []);

  const updateCurrPiece = (prevNext) => {
    return prevNext === "prev"
      ? currPiece > 0
        ? setCurrPiece(currPiece - 1)
        : setCurrPiece(windows.length - 1)
      : currPiece < windows.length - 1
      ? setCurrPiece(currPiece + 1)
      : setCurrPiece(0);
  };

  return (
    <Section data={data}>
      <Slider
        data={windows}
        currPiece={currPiece}
        updateCurrPiece={updateCurrPiece}
      />
    </Section>
  );
};

export default EveryWindowWeInstallSection;
