import * as React from 'react';
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
 
const Wrap = styled.div`
  text-align: center;
  margin: 0 0 5rem;
  h4 {
    margin-bottom: 1rem;
  }
`

const resourceHeight = "10rem";
const ResourceLink = styled.a`
  color: white;
  position: relative;
  width: 100%;
  height: ${resourceHeight};
  font-weight: bold;
  display: block;
  &:hover {
    text-decoration: none;
    color: white;
  }
`;
const BgWrap = styled.div`
  position: relative;
  left: 0;
  top: 0;
  z-index: -2;
  img {
    width: 100%;
    height: ${resourceHeight};
    object-fit: cover;
    position: relative;
    z-index: -2;
  }
  .darken-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.25);
  }
`;
const TextWrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: "white" !important;
  text-shadow: 1px 1px 2px "black";
`;
 
const ResourceTiles = props => {
  return (
    <Wrap>
      <Container>
        <h4>Resources</h4>
        <Row style={{ margin: "0 10vw" }}>
          <Col md={4}>
            <ResourceLink href="https://coloradosidingrepair.com/siding-brands">
              <BgWrap>
                <img
                  src="https://wp.coloradosidingrepair.com/wp-content/uploads/2020/02/KB-COSiding2-24.jpg"
                  alt="shingled siding"
                />
                <div className="darken-bg" />
              </BgWrap>
              <TextWrap>
                <h5>Type of Siding</h5>
                <p>Choosing the Right Type</p>
              </TextWrap>
            </ResourceLink>
          </Col>
          <Col md={4}>
            <ResourceLink href="https://coloradosidingrepair.com/financing">
              <BgWrap>
                <img
                  src="https://wp.coloradosidingrepair.com/wp-content/uploads/2020/07/sign-papers.jpg"
                  alt="signing paperwork"
                />
                <div className="darken-bg" />
              </BgWrap>
              <TextWrap>
                <h5>Financing Options</h5>
              </TextWrap>
            </ResourceLink>
          </Col>
          <Col md={4}>
            <ResourceLink href="https://coloradosidingrepair.com/project-portfolio">
              <BgWrap>
                <img
                  src="https://wp.coloradosidingrepair.com/wp-content/uploads/elementor/thumbs/KB-COSiding-1-copy-e1589244598885-orsgh79lbbt5034ejzw1kwnigtfr3snbetoyuzdm3s.jpg"
                  alt="white house with siding installed by Colorado Siding Repair"
                />
                <div className="darken-bg" />
              </BgWrap>
              <TextWrap>
                <h5>Check Out Our Work</h5>
              </TextWrap>
            </ResourceLink>
          </Col>
        </Row>
      </Container>
      
    </Wrap>
  )
}
 
export default ResourceTiles