import { INLINES } from "@contentful/rich-text-types";
import { get } from "lodash";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { RichText } from "../../../classes/RichText";
import Theme from "../../../vars/ThemeOptions";
import { RichTextReact } from "../../RichTextReact/RichTextReact";

const Wrap = styled.div`
  border-top: 2px solid black;
  border-bottom: 15px solid black;
  padding-top: 2rem;
  background: white;
`;
const ContentCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  h1 {
    font-size: 3.25rem;
    letter-spacing: 1px;
    margin-bottom: 0;
    font-weight: 400;
  }
  h2 {
    font-size: 3.25rem;
    color: ${Theme.hex("primary")};
    font-weight: bold;
  }
  p:first-of-type {
    width: 29rem;
  }
  h3 {
    margin-top: 3rem;
    margin-bottom: -2rem;
    color: ${Theme.hex("primary")};
    font-weight: 100;
    font-style: italic;
  }
  a {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    background: black;
    border: 5px solid white;
    padding: 10px;
    height: 68px;
    position: relative;
    bottom: -58px;
    left: 0;
    z-index: 100;
    &:hover {
      text-decoration: none;
      color: ${Theme.hex("secondary")};
    }
  }
`;

const TitleSubtitleCta = ({ data, route }) => {
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        // console.log('node: ', node);
        const copy = get(node, "content[0].value");
        const href = get(node, "data.uri");
        return (
          <a href={href} className={route}>
            {copy}
          </a>
        );
      }
    }
  };
  const rt = new RichText(get(data, "richText1"));
  return (
    <Wrap>
      <Container>
        <Row>
          <ContentCol>
            <RichTextReact content={rt} options={options} />
          </ContentCol>
        </Row>
      </Container>
    </Wrap>
  );
};

export default TitleSubtitleCta;
