import * as React from "react";
import styled from "styled-components";
import get from "lodash/get";
import SectionTitle from "../../common/Section/SectionTitle";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";

const Wrap = styled.div`
  * {
    transition: all 0.3s ease;
  }
  img {
    width: 100%;
  }
`;

const ThreeLittlePigs = ({ data }) => {
  const richText1 = new RichText(get(data, "richText1"));
  const richText2 = new RichText(get(data, "richText2"));
  const fileRef = get(data, "fileRef");
  const fluid = get(fileRef, "gatsbyImageData");
  const title = get(fileRef, "title");

  return (
    <Wrap>
      <SectionTitle
        title={() => <RichTextReact content={richText1} />}
        titleColor="black"
        bold
      />
      <RichTextReact content={richText2} />
      {fluid ? <GatsbyImage image={fluid} alt={title} /> : null}
    </Wrap>
  );
};

export default ThreeLittlePigs;
