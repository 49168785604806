import * as React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Section from "../../common/Section/Section";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";
import { BiCheck } from "react-icons/bi";
import Theme from "../../../vars/ThemeOptions";

const Wrap = styled.div`
  color: white;
  .list-item {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    .gatsby-image-wrapper {
      margin-right: 0.75rem;
    }
    p {
      margin: 8px 0 0 0;
    }
  }
`;

export const checkMarkBullets = (node, children) => (
  <div className="list-item" style={{ display: "flex", alignItems: "center" }}>
    <div className="check-wrap">
      <BiCheck
        style={{
          fontSize: `2rem`,
          color: Theme.color.primary.hex,
          marginRight: `.25rem`
        }}
      />
    </div>
    <div>{node.content[0].content[0].value}</div>
  </div>
);

const EnergyEfficientSection = ({ data }) => {
  // console.log("energy data: ", data)
  const { richText1, richText2 } = data ? data : { data: null };
  const rt1 = new RichText(richText1);
  const rt2 = new RichText(richText2);
  const options = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: checkMarkBullets
    }
  };
  return (
    <Wrap>
      <Section data={data} bgFade>
        <Container fluid>
          <Row>
            <Col lg={9} md={12}>
              <RichTextReact content={rt1} />
            </Col>
          </Row>
          <Row>
            <Col lg={7} md={12}>
              <RichTextReact content={rt2} options={options} />
            </Col>
          </Row>
        </Container>
      </Section>
    </Wrap>
  );
};

export default EnergyEfficientSection;
