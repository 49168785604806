import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { MdFormatQuote } from "react-icons/md";
import Theme from "../../../vars/ThemeOptions";
import get from "lodash/get";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";

const Wrap = styled.div`
  position: relative;
  background: white;
  border-top: 10px solid white;
  // top: -8vw;
`;

const TestimonialWrap = styled.div`
  width: 100vw;
  min-height: 20rem;
  position: relative;
  border-bottom: 10px solid white;
  // margin: 10px 0;
  .bg-wrap {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`;

const WrapCont = styled(Container)`
  position: relative;
  z-index: 10;
  .row {
    height: 20rem;
  }
`;

const ContentWrap = styled.div`
  position: relative;
  text-align: center;
  padding: 3rem 0;
  p {
    color: white !important;
  }
  p:last-of-type {
    font-weight: bold;
    font-size: 1.2rem;
  }
`;

const Quotation = styled(MdFormatQuote)`
  font-size: 5rem;
  color: ${Theme.hex("primary")};
  transform: rotate(180deg);
`;

const Testimonials = ({ data, divider }) => {
  const dividerFluid = get(divider, `gatsbyImageData`);
  const dividerAlt = get(divider, `title`);
  return (
    <Wrap>
      <div className="divider-wrap">
        {dividerFluid ? (
          <GatsbyImage image={dividerFluid} alt={dividerAlt} />
        ) : null}
      </div>

      {data.map((piece, i) => {
        const fluid = get(
          piece,
          `image.gatsbyImageData`,
          get(piece, `gatsbyImageData`)
        );
        const alt = get(piece, `image.title`);
        const rt = get(piece, `copy`);
        const rtext = new RichText(rt);
        return (
          <TestimonialWrap key={piece.title + i}>
            <WrapCont>
              <Row>
                {i % 2 === 0 ? <Col xs={12} lg={6} /> : null}
                <Col xs={12} lg={6}>
                  <ContentWrap>
                    <Quotation />
                    <RichTextReact content={rtext} />
                    <p>{piece.title && piece.title}</p>
                  </ContentWrap>
                </Col>
                {i % 2 !== 0 ? <Col xs={12} lg={6} /> : null}
              </Row>
            </WrapCont>
            <div className="bg-wrap">
              {fluid ? <GatsbyImage image={fluid} alt={alt} /> : null}
            </div>
          </TestimonialWrap>
        );
      })}
    </Wrap>
  );
};

export default Testimonials;
