import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import get from "lodash/get";
import PigHero from "../LPPigPromo/PigHero";
import TopSection from "./TopSection";
import BottomSection from "./BottomSection";
import HomeShowForm from "./Form";

const Wrap = styled.div`
  * {
    transition: all 0.3s ease;
  }
  padding: 2rem 0;
`;

export const serviceInterestOptions = [
  `Partial Siding Replacement`,
  `Full Siding Replacement`,
  `Siding Repair`,
  `Window Installation`,
  `Hail Damage Siding`,
  `Vinyl Siding Installation`,
  `Need Help Determining`
];
export const howDidHearOptions = [
  "Google Search",
  "Facebook",
  "LinkedIn",
  "Instagram",
  "Neighbor",
  "NextDoor",
  "Special Event",
  "Radio",
  "5280 Magazine",
  "Personal Referral",
  "Networking Group",
  "Yelp",
  "BBB",
  "Other"
];
export const sidingTypeOptions = [
  "James Hardie Siding",
  "Cedar Siding",
  "Composite Wood Siding",
  "Vinyl Siding",
  "Metal Siding",
  "Unsure",
  "Whatever I've got now!",
  "Open to Suggestions"
];
export const whenToStartOptions = [
  `1-2 Weeks`,
  `More than 2 Weks`,
  `Timing is Flexible`
];

const LPPigPromo = (props) => {
  const data = useStaticQuery(graphql`
    query {
      heroData: contentfulGlobal(title: { eq: "LP Home Show - Hero Content" }) {
        ...NodeGlobalFields
      }
      TopSectionData: contentfulGlobal(
        title: { eq: "LP Home Show - Top Section" }
      ) {
        ...NodeGlobalFields
      }
      howToEnterData: contentfulGlobal(
        title: { eq: "LP Home Show - Bottom Section" }
      ) {
        ...NodeGlobalFields
      }
    }
  `);
  const heroData = get(data, "heroData");
  const TopSectionData = get(data, "TopSectionData");
  const howToEnterData = get(data, "howToEnterData");
  //console.log(heroData, TopSectionData, howToEnterData);
  return (
    <Wrap>
      <PigHero data={heroData} />
      <Container>
        <Row>
          <Col
            md={{ span: 12, order: 1 }}
            lg={{ span: 6, order: 3 }}
            style={{ position: "relative" }}>
            <HomeShowForm
              config={{
                thankYouPage: `/thank-you`,
                name: `homeShowForm`,
                postUrl: `/why-choose-us`
                // consoleMessage: true
              }}
              // title={
              //   "Get in touch - we are looking forward to hearing from you!"
              // }
              fields={[
                { as: `name` },
                { as: `email` },
                { as: `phone` },
                { as: `address` },
                {
                  as: `single`,
                  props: {
                    name: `companyRep`,
                    label: `Company Representative`
                  }
                },

                {
                  as: `select`,
                  props: {
                    name: `fullOrPartial`,
                    label: `Which service are you interested in?`,
                    options: serviceInterestOptions,
                    isRequired: true
                  }
                },
                {
                  as: `select`,
                  props: {
                    name: `howDidYouHearAboutUs`,
                    label: `How did you hear about us?`,
                    options: howDidHearOptions,
                    isRequired: true
                  }
                },
                {
                  as: `single`,
                  props: {
                    name: `appointmentDateAndTime`,
                    label: `Appointment Date/Time`
                  }
                },
                {
                  as: `select`,
                  props: {
                    name: `desiredSidingType`,
                    label: `Select a Siding Type`,
                    options: sidingTypeOptions,
                    isRequired: true
                  }
                },
                {
                  as: `checkbox`,
                  props: {
                    name: `financing`,
                    label: `Interested in Financing?`,
                    text: `Yes, send me information about applying for financing.`
                  }
                },
                { as: `message` }
              ]}
            />
          </Col>
          <Col md={{ span: 12, order: 3 }} lg={{ span: 1, order: 2 }}></Col>
          <Col md={{ span: 12, order: 2 }} lg={{ span: 5, order: 1 }}>
            <TopSection data={TopSectionData} />
            <BottomSection data={howToEnterData} />
          </Col>
        </Row>
      </Container>
    </Wrap>
  );
};

export default LPPigPromo;
