import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import TitleSubtitleCta from "./TitleSubtitleCta";
import MainContent from "./MainContent";
import CopyAndCta from "./CopyAndCta";
import Testimonials from "../IdentifyingNeedsPage/Testimonials";
import { ParallaxProvider } from "react-scroll-parallax";
import get from "lodash/get";
import { Img } from "../../../classes/Img";

const Wrap = styled.div`
  font-family: Roboto, Arial;
  a {
    transform: scale(1);
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
      transition: all 0.3s ease;
    }
  }
`;

const LPGeofence = ({ route, hero }) => {
  const data = useStaticQuery(query);
  const tscData = get(data, "titleSubtitleCta");
  const mcData = get(data, "mainContent");
  const mcBackgrounds = get(data, "mainContentBackgrounds.media", []).map(
    (i) => new Img(i)
  );
  const iconData = get(data, "iconsAndCopy.edges").map((p) => p.node);
  const ccData = get(data, "copyAndCta");
  const testimonialData = get(data, `testimonials.edges`).map((p) => p.node);
  return (
    <ParallaxProvider>
      <Wrap>
        <TitleSubtitleCta data={tscData} route={route} />
        <MainContent
          data={mcData}
          backgrounds={mcBackgrounds}
          iconData={iconData}
          route={route}
          hero={hero}
        />
        <CopyAndCta data={ccData} route={route} />
        <Testimonials data={testimonialData} />
      </Wrap>
    </ParallaxProvider>
  );
};

export default LPGeofence;

const query = graphql`
  query {
    titleSubtitleCta: contentfulGlobal(
      title: { eq: "LP Siding Experts - Sec 1 - Title, Subtitle, and CTA" }
    ) {
      ...NodeGlobalFields
    }
    mainContent: contentfulGlobal(
      title: { eq: "LP Siding Experts - Sec 2 - Main Content" }
    ) {
      ...NodeGlobalFields
    }
    mainContentBackgrounds: contentfulImageGallery(
      title: { eq: "Geofence Page Backgrounds" }
    ) {
      media {
        title
        gatsbyImageData
      }
    }
    copyAndCta: contentfulGlobal(
      title: { eq: "LP Siding Experts - Sec 3 - Copy and CTA" }
    ) {
      ...NodeGlobalFields
    }
    iconsAndCopy: allContentfulTitleCopyImage(
      filter: { section: { eq: "LP Siding Experts" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          ...RichTextTitleCopyImage
        }
      }
    }
    testimonials: allContentfulTitleCopyImage(
      filter: { section: { eq: "testimonials" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          ...RichTextTitleCopyImage
        }
      }
    }
  }
`;
