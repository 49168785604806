import * as React from "react";
import styled from "styled-components";
import Title from "../../common/Section/SectionTitle";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";
import get from "lodash/get";

const Wrap = styled.div`
  .divider-wrap {
    position: relative;
    top: -9rem;
    //margin-bottom: -11rem;
  }
  @media only screen and (max-width: 990px) {
    .divider-wrap {
      top: -7vw;
      //margin-bottom: -3rem;
    }
  }
`;

const WrapCont = styled(Container)`
  padding-right: 25vw;
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .margined {
    margin: 2rem 0;
  }
  @media only screen and (max-width: 990px) {
    padding-right: 0;
  }
`;

const IconTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image-wrap {
    height: 7rem;
    width: 7rem;
    text-align: center;
    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }
    &.icon-3 {
      width: 8.75rem;
    }
  }
  p {
    margin-top: 0.5rem;
    font-size: 1.3rem;
    font-weight: 600;
  }
`;

const IdentifyingNeedsSection = ({ data, divider }) => {
  const iconTextData = useStaticQuery(graphql`
    query {
      allContentfulTitleCopyImage(
        filter: { section: { eq: "identifying" } }
        sort: { fields: order, order: ASC }
      ) {
        edges {
          node {
            title
            image {
              gatsbyImageData
            }
          }
        }
      }
    }
  `).allContentfulTitleCopyImage.edges.map((p) => p.node);
  // console.log('data from identifyingNeedsSec: ', data)
  const { title } = data ? data : null;
  const { fluid } = divider ? divider : null;
  const alt = divider ? divider.title : null;
  const rt = new RichText(data.richText1);
  const rt2 = new RichText(data.richText2);
  return (
    <Wrap>
      <WrapCont>
        <Row>
          <Col>
            <Title title={title} />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextReact content={rt} />
          </Col>
        </Row>
        <Row className="margined">
          {iconTextData.map((piece, i) => {
            const fluid = get(piece, `image.gatsbyImageData`);
            const alt = piece.image ? piece.image.title : null;
            return (
              <Col
                xs={i === 2 ? 12 : 6}
                lg={4}
                className="centered"
                key={piece.title + i}>
                <IconTextWrap>
                  <div className={`image-wrap icon-` + (i + 1)}>
                    {fluid ? <GatsbyImage image={fluid} alt={alt} /> : null}
                  </div>
                  <p>{piece.title}</p>
                </IconTextWrap>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col>
            <RichTextReact content={rt2} />
          </Col>
        </Row>
      </WrapCont>
      <div className="divider-wrap">
        {fluid ? <GatsbyImage image={fluid} alt={alt} /> : null}
      </div>
    </Wrap>
  );
};

export default IdentifyingNeedsSection;
