import * as React from "react";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";
import Layout from "../components/layout/MainLayout";
import { WmkSeo } from "wmk-lib";
import { dataHash } from "./pageDataHash";
import get from "lodash/get";
import { graphql } from "gatsby";
import { Img } from "../classes/Img";

const PageNode = ({ pageContext, data }) => {
  const route = get(pageContext, "slug");
  const renderData = get(dataHash, route);
  const RenderComponent = get(renderData, "RenderComponent");
  const layoutProps = get(renderData, "layoutProps");
  const hero = new Img(get(data, `page.hero`));
  const _pt = get(data, `page.titleBar`);
  const pageTitle = _pt ? _pt : get(pageContext, `title`);
  const props = {
    route,
    hero
  };
  return renderData ? (
    <Layout layoutProps={layoutProps}>
      <WmkSeo.Meta
        title={pageTitle}
        path=""
        slug={pageContext.slug}
        ogImage={null}
        twitterImage={null}
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      {typeof RenderComponent === "function" ? (
        <RenderComponent {...props} />
      ) : (
        RenderComponent
      )}
    </Layout>
  ) : null;
};

export default PageNode;

export const query = graphql`
  query pageQuery($slug: String!) {
    page: contentfulPages(slug: { eq: $slug }) {
      ...NodePageFields
    }
  }
`;
