import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import Section from "../../common/Section/Section";
import Theme from "../../../vars/ThemeOptions";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";

const headHeight = "150px";

const Wrap = styled.div`
  position: relative;
  top: -${headHeight};
  margin-bottom: -${headHeight};
  color: white;
  .cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
    text-transform: uppercase;
    h5 {
      font-weight: 300;
      margin-bottom: 0;
    }
    a {
      color: white;
      font-size: 1.3rem;
      font-weight: 300;
      background: ${Theme.hex("primary")};
      padding: 5px 30px 2px;
      opacity: 1;
      transition: opacity 0.3s ease;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
        transition: opacity 0.3s ease;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0 !important;
        }
        b {
          font-size: 2rem;
          font-weight: 700;
          margin-left: 0.75rem;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    top: -175px;
  }
`;

const WhyChooseUsHero = ({ data }) => {
  const [dataLayerObj, setDataLayerObj] = useState();
  const { richText1, shortText1, shortText2, shortText3, shortText4 } = data
    ? data
    : { data: null };
  useEffect(() => {
    const globalDataLayer = window.dataLayer;
    if (globalDataLayer && typeof globalDataLayer === "object") {
      setDataLayerObj(globalDataLayer);
    }
  }, [data]);
  const rt = new RichText(richText1);
  return (
    <Wrap>
      <Section
        data={data}
        titleColor="white"
        titleSize="2.5rem"
        bgFade
        padding={headHeight + ` 0 0 0`}>
        <Row>
          <Col md={6} xs={12}>
            <div className="copy">
              <RichTextReact content={rt} />
            </div>
            {shortText1 && shortText2 && shortText3 && (
              <div className="cta">
                <h5>{shortText1}</h5>
                <a
                  href={shortText4}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={
                    dataLayerObj
                      ? () => {
                          dataLayerObj.push({
                            event: "getQuote",
                            button: "noPressureQuote",
                            campaign: "whyChooseUs"
                          });
                        }
                      : undefined
                  }>
                  <span>
                    {shortText2 + ` `} <b>{shortText3}</b>
                  </span>
                </a>
              </div>
            )}
          </Col>
        </Row>
      </Section>
    </Wrap>
  );
};

export default WhyChooseUsHero;
