import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import get from "lodash/get";

const Wrap = styled.div`
  position: absolute;
  z-index: 0;
  bottom: 43vw;
  right: -100vw;
  .image-wrap {
    width: 100vw;
    height: 50vw;
    position: absolute;
    right: -30vw;
    transition: right 1s ease;
    &.current {
      right: 23vw;
      transition: right 0.75s ease 0.25s;
    }
  }
  @media only screen and (min-width: 2200px) {
    bottom: 35vw;
    .image-wrap {
      width: 80vw;
      height: 40vw;
      right: -30vw;
      &.current {
        right: 26vw;
      }
    }
  }
  @media only screen and (min-width: 1800px) and (max-width: 2199px) {
    bottom: 35vw;
    .image-wrap {
      width: 80vw;
      height: 40vw;
      right: -30vw;
      &.current {
        right: 30vw;
      }
    }
  }
  @media only screen and (max-width: 1400px) {
    bottom: 35vw;
    .image-wrap {
      width: 80vw;
      height: 40vw;
      right: -30vw;
      &.current {
        right: 45vw;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;

const SlidingWorkers = (props) => {
  const images = useStaticQuery(graphql`
    query {
      first: contentfulAsset(title: { eq: "Worker 1" }) {
        title
        gatsbyImageData
      }
      second: contentfulAsset(title: { eq: "Worker 2" }) {
        title
        gatsbyImageData
      }
      third: contentfulAsset(title: { eq: "Worker 3" }) {
        title
        gatsbyImageData
      }
    }
  `);
  const [currWorker, setCurrWorker] = useState(1);
  const nextWorker = () => {
    return currWorker === 2 ? setCurrWorker(0) : setCurrWorker(currWorker + 1);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      nextWorker();
    }, 5000);
    return () => clearInterval(interval);
  });
  const first = get(images, `first.gatsbyImageData`);
  const firstAlt = get(images, `first.title`);
  const second = get(images, `second.gatsbyImageData`);
  const secondAlt = get(images, `second.title`);
  const third = get(images, `third.gatsbyImageData`);
  const thirdAlt = get(images, `third.title`);
  return (
    <Wrap>
      <div className={currWorker === 0 ? "image-wrap current" : "image-wrap"}>
        {first ? <GatsbyImage image={first} alt={firstAlt} /> : null}
      </div>
      <div className={currWorker === 1 ? "image-wrap current" : "image-wrap"}>
        {second ? <GatsbyImage image={second} alt={secondAlt} /> : null}
      </div>
      <div className={currWorker === 2 ? "image-wrap current" : "image-wrap"}>
        {third ? <GatsbyImage image={third} alt={thirdAlt} /> : null}
      </div>
    </Wrap>
  );
};

export default SlidingWorkers;
