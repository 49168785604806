import React from 'react'
import styled from 'styled-components'
import Headings from './Headings'
import Row from './Row'
 
const Wrap = styled.div`
  color: white;
  @media only screen and (max-width: 990px) {
    display: none;
  }
`
 
const DesktopTable = ({ materials }) => {
  
  return (
    <Wrap>
      <table>
        <thead>
          <Headings />
        </thead>
        <tbody>
          {materials.map((material, i) => {
            return <Row data={material} key={material.name + i} />
          })}
        </tbody>        
      </table>
    </Wrap>
  )
}
 
export default DesktopTable