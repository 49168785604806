import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Section from "../../common/Section/Section";
import Theme from "../../../vars/ThemeOptions";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";
import get from "lodash/get";

const Wrap = styled.div`
  .icon-text-sec-wrap {
    display: flex;
    margin: 2rem 0 1rem;
  }
  span {
    b {
      color: ${Theme.hex("primary")};
    }
  }
`;

const IconTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :first-of-type {
    margin-right: 6vw;
  }
  .image-wrap {
    height: 5rem;
    width: 5rem;
    text-align: center;
    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }
    &.icon-3 {
      width: 8.75rem;
    }
  }
  p {
    margin-top: 0.5rem;
    font-size: 1.3rem;
    font-weight: 600;
  }
`;

const IdentifyingWindowNeedsSec = ({ data, fixedPricing, eduApproach }) => {
  // console.log("IdentifyingWindowNeedsSec data: ", data)
  const { richText1, shortText1, shortText2 } = data ? data : { data: null };
  const eduImg = get(eduApproach, `image.gatsbyImageData`);
  const fixedImg = get(fixedPricing, `image.gatsbyImageData`);
  const rt = new RichText(richText1);
  return (
    <Wrap>
      <Section data={data} padding="1rem 0 3rem 0">
        <RichTextReact content={rt} />
        <div className="icon-text-sec-wrap">
          {fixedPricing && (
            <IconTextWrap className="pricing">
              <div className="image-wrap">
                {fixedImg ? (
                  <GatsbyImage
                    image={fixedImg}
                    alt={fixedPricing.title}
                    objectFit={"contain"}
                  />
                ) : null}
              </div>
              <p>{fixedPricing.title}</p>
            </IconTextWrap>
          )}
          {eduApproach && (
            <IconTextWrap className="pricing">
              <div className="image-wrap">
                {eduImg ? (
                  <GatsbyImage
                    image={eduImg}
                    alt={fixedPricing.title}
                    objectFit={"contain"}
                  />
                ) : null}
              </div>
              <p>{eduApproach.title}</p>
            </IconTextWrap>
          )}
        </div>
        <span>
          {shortText1 + ` `}
          <b>{shortText2}</b>
        </span>
      </Section>
    </Wrap>
  );
};

export default IdentifyingWindowNeedsSec;
