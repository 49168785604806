import * as React from "react";
import styled from "styled-components";
import get from "lodash/get";
import SectionTitle from "../../common/Section/SectionTitle";
import Theme from "../../../vars/ThemeOptions";
import { RichText } from "../../../classes/RichText";
import { RichTextReact } from "../../RichTextReact/RichTextReact";

const Wrap = styled.div`
  * {
    transition: all 0.3s ease;
  }
  padding: 2rem 0;
  b,
  ol {
    color: ${Theme.hex("primary")};
    font-weight: bold;
  }
  p {
    margin-bottom: 10px;
  }
`;

const HowToEnter = ({ data }) => {
  const richText1 = new RichText(get(data, "richText1"));
  return (
    <Wrap>
      <SectionTitle
        title={() => <RichTextReact content={richText1} />}
        titleColor="black"
        bold
      />
    </Wrap>
  );
};

export default HowToEnter;
